<!--TagsModal.vue-->
<template>
    <at-modal v-model="show" @on-cancel="close">
        <template v-slot:header>
            <h3>{{ $t('dashboard.tagSpan') }}</h3>
        </template>
        <div class="modal-content">
            <at-checkbox-group v-model="localSelectedTags" class="tags-list">
                <at-checkbox
                    v-for="tag in tags"
                    :key="tag.attributes.name"
                    :label="tag.attributes.name"
                    :value="tag.attributes.name"
                    class="tag-entry"
                >
                    <span class="tag-color-indicator" :style="{ backgroundColor: tag.attributes.color }"></span>
                    <span class="tag-name">{{ tag.attributes.name }}</span>
                </at-checkbox>
            </at-checkbox-group>
        </div>
        <template v-slot:footer>
            <at-button @click="close">{{ $t('dashboard.buttons.close') }}</at-button>
        </template>
    </at-modal>
</template>

<script>
    export default {
        name: 'TagsModal',
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            tags: {
                type: Array,
                required: true,
            },
            selectedTags: {
                type: Array,
                default: () => [],
            },
        },
        data() {
            return {
                show: this.value,
                localSelectedTags: this.selectedTags,
            };
        },
        watch: {
            value(val) {
                this.show = val;
            },
            show(val) {
                this.$emit('input', val);
            },
            selectedTags(newVal) {
                this.localSelectedTags = newVal;
            },
        },
        methods: {
            close() {
                this.show = false;
                this.$emit('update:selectedTags', this.localSelectedTags);
            },
            updateSelectedTags() {
                this.$emit('update:selectedTags', this.localSelectedTags);
            },
        },
    };
</script>
