<template>
    <div class="timer">
        <p>{{ formattedTime }}</p>
    </div>
</template>

<script>
    export default {
        name: 'Timer',
        props: {
            time: {
                type: Number,
                required: true,
            },
            isRunning: {
                type: Boolean,
                required: true,
            },
        },
        data() {
            return {
                formattedTime: '',
                interval: null,
            };
        },
        methods: {
            formatTime() {
                const hours = Math.floor(this.time / 3600);
                const minutes = Math.floor((this.time % 3600) / 60);
                const seconds = this.time % 60;
                this.formattedTime = [
                    hours.toString().padStart(1, '0'),
                    minutes.toString().padStart(2, '0'),
                    seconds.toString().padStart(2, '0'),
                ].join(':');
            },
            startInterval() {
                if (this.isRunning && !this.interval) {
                    this.interval = setInterval(this.formatTime, 1000);
                }
            },
            stopInterval() {
                if (this.interval) {
                    clearInterval(this.interval);
                    this.interval = null;
                }
            },
            updateTimer() {
                this.formatTime();
                if (this.isRunning) {
                    this.startInterval();
                } else {
                    this.stopInterval();
                }
            },
        },
        mounted() {
            this.updateTimer();
        },
        updated() {
            this.updateTimer();
        },
        watch: {
            time() {
                this.updateTimer();
            },
        },
    };
</script>
