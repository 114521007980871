<!-- TimeDisplay -->
<template>
    <div ref="dropdownContainer">
        <div class="time-display" @click="toggleDropdown">
            {{ formattedDate }}
        </div>
        <transition name="fade">
            <div v-if="isDropdownVisible" class="dropdown">
                <div class="time-inputs">
                    <div class="time-inputs-group">
                        <label for="start-time">Start</label>
                        <div class="time-inputs-group-date">
                            <at-input
                                id="start-time"
                                v-model="startTime"
                                :class="{ 'invalid-input': !isValidStartTime }"
                                placeholder="start time"
                                @input="changeDateTime('start_at', selectedDate, $event)"
                            ></at-input>

                            <span class="start-date-display">{{ startDateFormat }}</span>
                        </div>
                        <span v-if="!isValidStartTime" class="error-message"> Time is incorrect </span>
                    </div>
                    <div class="time-inputs-group">
                        <label for="end-time">Stop</label>
                        <div class="time-inputs-group-date">
                            <at-input
                                id="end-time"
                                v-model="endTime"
                                :class="{ 'invalid-input': !isValidEndTime }"
                                placeholder="end time"
                                @input="changeDateTime('end_at', endDateParams, $event)"
                            ></at-input>
                            <span class="end-date-display">{{ endDateFormat }}</span>
                        </div>
                        <span v-if="!isValidEndTime" class="error-message"> Time is incorrect</span>
                    </div>
                </div>
                <v-date-picker
                    v-model="selectedDate"
                    no-title
                    :active="[startDate, endDate]"
                    @input="changeDate"
                ></v-date-picker>
            </div>
        </transition>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import moment from 'moment';

    export default {
        name: 'TimeDisplay',
        props: {
            startAt: {
                type: String,
                required: true,
            },
            endAt: {
                type: String,
                required: true,
            },
        },
        data() {
            return {
                isValidStartTime: true,
                isValidEndTime: true,
                isDropdownVisible: false,
                startTime: '22:00',
                endTime: '01:00',
                selectedDate: null,
                time: null,
                startAtDate: null,
                startDate: new Date().toISOString().substr(0, 10),
                endDate: new Date().toISOString().substr(0, 10),
                lastValidTime: {
                    start: '22:00',
                    end: '01:00',
                },
            };
        },
        computed: {
            startDateFormat() {
                return moment(this.selectedDate, 'YYYY-MM-DD').format('DD/MM');
            },
            endDateFormat() {
                let startDate = moment(this.selectedDate, 'YYYY-MM-DD');
                let startTime = moment(this.startTime, 'HH:mm');
                let endTime = moment(this.endTime, 'HH:mm');
                let endDate = endTime.isBefore(startTime) ? startDate.clone().add(1, 'day') : startDate;
                return endDate.format('DD/MM');
            },
            endDateParams() {
                let startDate = moment(this.selectedDate, 'YYYY-MM-DD');
                let startTime = moment(this.startTime, 'HH:mm');
                let endTime = moment(this.endTime, 'HH:mm');
                let endDate = endTime.isBefore(startTime) ? startDate.clone().add(1, 'day') : startDate;
                return endDate.format('YYYY-MM-DD');
            },
            startDateParams() {
                return moment(this.selectedDate, 'YYYY-MM-DD').format('YYYY-MM-DD');
            },
            formattedDate() {
                const start = moment(this.startTime, 'hh:mm:ss A').format('HH:mm:ss');
                const end = moment(this.endTime, 'hh:mm:ss A').format('HH:mm:ss');
                return `${start} - ${end}`;
            },
        },
        methods: {
            validateAndUpdate(type, value) {
                this.validateTime(type, value);
                if (this.isValidStartTime && this.isValidEndTime) {
                    this.updateDateTime();
                }
            },
            allowedDates(date) {
                return this.isStartDate(date) || this.isEndDate(date);
            },
            isStartDate(date) {
                return moment(this.startDate).isSame(date, 'day');
            },
            isEndDate(date) {
                return moment(this.endDate).isSame(date, 'day');
            },
            formatDate(date) {
                return moment(date).format('YYYY-MM-DD');
            },
            validateTime(type, value) {
                const isValid = this.validateTimeFormat(value);
                this[`isValid${type.charAt(0).toUpperCase() + type.slice(1)}Time`] = isValid;
                if (isValid) {
                    this.lastValidTime[type] = value;
                    return true;
                }
            },
            validateTimeFormat(value) {
                const timePattern = /^([01]?[0-9]|2[0-3]):[0-5][0-9]:[0-5][0-9]$/;
                return timePattern.test(value);
            },
            toggleDropdown() {
                this.isDropdownVisible = !this.isDropdownVisible;
            },
            setInitialTime() {
                const startTime = this.time.split(' - ')[0];
                const endTime = this.time.split(' - ')[1];
                if (this.validateTime('start', startTime) && this.validateTime('end', endTime)) {
                    this.lastValidTime.start = startTime;
                    this.lastValidTime.end = endTime;
                    this.startTime = startTime;
                    this.endTime = endTime;
                }
            },
            closeDropdownOnClickOutside(event) {
                if (this.isDropdownVisible && !this.$refs.dropdownContainer.contains(event.target)) {
                    this.isDropdownVisible = false;
                }
            },
            updateDateTime() {
                if (this.isValidStartTime && this.isValidEndTime) {
                    let startDate = moment(this.selectedDate, 'YYYY-MM-DD');
                    let startTime = moment(this.startTime, 'HH:mm');
                    let endTime = moment(this.endTime, 'HH:mm');
                    this.startDate = startDate.format('YYYY-MM-DD');
                    if (endTime.isBefore(startTime)) {
                        this.endDate = startDate.add(1, 'day').format('YYYY-MM-DD');
                    } else {
                        this.endDate = this.startDate;
                    }
                }
            },
            initialData() {
                const start = new Date(this.startAt);
                const end = new Date(this.endAt);
                this.time = `${this.formatTime(start)} - ${this.formatTime(end)}`;
                this.selectedDate = start.toISOString().split('T')[0];
            },

            formatTime(date) {
                let hours = date.getHours();
                let minutes = date.getMinutes();
                let seconds = date.getSeconds();

                hours = hours.toString().padStart(2, '0');
                minutes = minutes.toString().padStart(2, '0');
                seconds = seconds.toString().padStart(2, '0');

                return `${hours}:${minutes}:${seconds}`;
            },
            changeDateTime: debounce(async function (type, date, time) {
                if (this.validateTimeFormat(time)) {
                    const newDate = new Date(`${date} ${time}`);
                    this.$emit('update:date', { [type]: newDate });
                }
            }, 350),
            changeDate(date) {
                const startAt = new Date(`${date} ${this.startTime}`);
                const endAt = new Date(`${date} ${this.endTime}`);
                this.$emit('update:date', { start_at: startAt, end_at: endAt });
            },
        },
        mounted() {
            document.addEventListener('click', this.closeDropdownOnClickOutside);
        },
        beforeDestroy() {
            document.removeEventListener('click', this.closeDropdownOnClickOutside);
        },
        created() {
            this.initialData();
            this.setInitialTime();
            this.updateDateTime();
        },
        watch: {
            startTime(newValue) {
                if (this.validateTime('start', newValue)) {
                    this.lastValidTime.start = newValue;
                } else {
                    this.startTime = this.lastValidTime.start;
                }
            },
            endTime(newValue) {
                if (this.validateTime('end', newValue)) {
                    this.lastValidTime.end = newValue;
                } else {
                    this.endTime = this.lastValidTime.end;
                }
            },
            startAt(newDate) {
                this.initialData();
                this.setInitialTime();
                this.updateDateTime();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .error-message {
        color: $color-error;
    }
    .time-display {
        cursor: pointer;
    }
    .invalid-input input {
        border: 1px solid $color-error;
    }

    .dropdown {
        position: absolute;
        z-index: 100;
        background-color: white;
        border: 1px solid #ddd;
        padding: 10px;
    }
</style>
