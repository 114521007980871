<template>
    <at-tooltip placement="bottom">
        <div class="additional-plans">
            <span :class="{ 'icon-active': isActive, 'icon-inactive': !isActive }" @click="switchActive">
                <img
                    class="icon-dollar-sign"
                    :src="require('../../../icon/dollar-sign.svg')"
                    :style="{ filter: iconFilter }"
                />
            </span>
        </div>
        <template #content>
            <span>{{ $t('dashboard.billableMessage') }}</span>
            <a href="#" @click="$emit('upgrade')">{{ $t('dashboard.clickUpgrade') }}</a>
        </template>
    </at-tooltip>
</template>

<script>
    import { mapState } from 'vuex';

    export default {
        name: 'AdditionalPlans',
        props: {
            selectedBillable: {
                type: Boolean,
                deafult: null,
            },
        },
        data() {
            return {
                isActive: false,
                showDropdown: false,
            };
        },
        computed: {
            ...mapState(['iconFilter']),
            iconFilter() {
                return this.isActive ? 'invert(100%)' : 'none';
            },
        },
        created() {
            this.isActive = this.selectedBillable == null ? false : this.selectedBillable;
        },
        methods: {
            switchActive() {
                this.isActive = !this.isActive;
                this.$emit('billable-status', this.isActive);
            },
        },
        watch: {
            selectedBillable(value) {
                this.isActive = value;
            },
        },
    };
</script>
