var render = function render(){var _vm=this,_c=_vm._self._c;return _c('at-modal',{on:{"on-cancel":_vm.closeModal},scopedSlots:_vm._u([{key:"header",fn:function(){return [_c('h3',[_vm._v(_vm._s(_vm.$t('dashboard.taskSpan')))])]},proxy:true},{key:"footer",fn:function(){return [_c('at-button',{staticClass:"create-project-button",on:{"click":_vm.createTask}},[_c('i',{staticClass:"icon icon-plus"}),_vm._v(" "+_vm._s(_vm.$t('dashboard.buttons.createTask'))+" ")])]},proxy:true}]),model:{value:(_vm.localShowModal),callback:function ($$v) {_vm.localShowModal=$$v},expression:"localShowModal"}},[_c('div',{staticClass:"modal-content"},[_c('div',{staticClass:"dropdown-search"},[_c('at-input',{attrs:{"placeholder":"Search project, task..."},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),(_vm.filteredTasks.length === 0)?_c('div',{staticClass:"not-found-message"},[_vm._v(_vm._s(_vm.$t('dashboard.noTasks')))]):_c('div',{staticClass:"projects-list"},_vm._l((_vm.filteredTasks),function(task,index){return _c('div',{key:index,staticClass:"project-entry",on:{"click":function($event){return _vm.selectAndClose(task)}}},[_c('span',{staticClass:"color-indicator",style:({
                        backgroundColor: task.attributes.color,
                        width: '10px',
                        display: 'inline-block',
                        height: '10px',
                        borderRadius: '50%',
                    })}),_c('span',{staticClass:"project-name",style:({ color: task.attributes.color })},[_vm._v(_vm._s(task.attributes.project.name))]),_vm._v(", "),_c('span',{staticClass:"task-name"},[_vm._v(_vm._s(task.attributes.task_name))]),_c('div',{staticClass:"project-desc"},[_vm._v(_vm._s(task.attributes.description))])])}),0)])])
}
var staticRenderFns = []

export { render, staticRenderFns }