//dashboard.service.js
import axios from 'axios';

class TimerService {
    async start(data) {
        return await axios.post('/api/v1/time-intervals/start', data);
    }

    async stop() {
        return await axios.post('/api/v1/time-intervals/stop');
    }

    update() {
        console.log('Update Timer');
    }
}
export default TimerService;
