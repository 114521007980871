<template>
    <div class="dashboard">
        <div class="dashboard__content-wrapper">
            <router-view :key="$route.fullPath" />
        </div>
    </div>
</template>

<script>
    export default {
        name: 'Index',
    };
</script>

<style lang="scss" scoped>
    .dashboard {
        &__routes {
            margin-bottom: 1em;
            display: flex;
            flex-wrap: wrap;
        }

        &__link {
            margin-right: $layout-03;
            font-size: 1.8rem;

            &:last-child {
                margin-right: initial;
            }

            a {
                color: #4b5675;
            }

            .router-link-active {
                color: $color-orange;
            }
        }
    }
    @media (max-width: 992px) {
        .dashboard {
            &__routes {
                flex-wrap: wrap;
            }
        }
    }
</style>
