var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{ref:"canvasWrapper",staticClass:"canvas"},[_c('canvas',{ref:"canvas"}),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.hoverPopup.show && !_vm.clickPopup.show),expression:"hoverPopup.show && !clickPopup.show"}],staticClass:"popup",style:({
            left: `${_vm.hoverPopup.x - 30}px`,
            bottom: `${_vm.height - _vm.hoverPopup.y + 10}px`,
        })},[(_vm.hoverPopup.event)?_c('div',[_vm._v(" "+_vm._s(_vm.hoverPopup.event.attributes.task_name)+" ("+_vm._s(_vm.hoverPopup.event.attributes.project_name)+") ")]):_vm._e(),(_vm.hoverPopup.event)?_c('div',[_vm._v(" "+_vm._s(_vm.formatDuration(_vm.hoverPopup.event.attributes.duration))+" ")]):_vm._e(),_c('a',{staticClass:"corner",style:({ left: `${_vm.hoverPopup.borderX}px` })})]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.clickPopup.show),expression:"clickPopup.show"}],staticClass:"popup",style:({
            left: `${_vm.clickPopup.x - 30}px`,
            bottom: `${_vm.height - _vm.clickPopup.y + 10}px`,
        })},[(_vm.clickPopup.event)?[_c('div',[_c('Screenshot',{attrs:{"disableModal":true,"lazyImage":false,"project":{
                        attributes: {
                            id: _vm.clickPopup.event.attributes.project_id,
                            name: _vm.clickPopup.event.attributes.project_name,
                        },
                    },"interval":_vm.clickPopup.event,"showText":false,"task":{
                        attributes: {
                            id: _vm.clickPopup.event.attributes.task_id,
                            task_name: _vm.clickPopup.event.attributes.task_name,
                        },
                    },"user":_vm.clickPopup.event.attributes},on:{"click":_vm.showPopup}})],1),_c('div',[_c('router-link',{attrs:{"to":`/tasks/view/${_vm.clickPopup.event.attributes.task_id}`}},[_vm._v(" "+_vm._s(_vm.clickPopup.event.attributes.task_name)+" ")]),_c('router-link',{attrs:{"to":`/projects/view/${_vm.clickPopup.event.attributes.project_id}`}},[_vm._v(" ("+_vm._s(_vm.clickPopup.event.attributes.project_name)+") ")])],1)]:_vm._e(),_c('a',{staticClass:"corner",style:({ left: `${_vm.clickPopup.borderX}px` })})],2),_c('ScreenshotModal',{attrs:{"project":_vm.modal.project,"interval":_vm.modal.interval,"show":_vm.modal.show,"showNavigation":true,"task":_vm.modal.task,"user":_vm.modal.user,"canRemove":true},on:{"close":_vm.onHide,"remove":_vm.onRemove,"showNext":_vm.showNext,"showPrevious":_vm.showPrevious}})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }