<template>
    <div class="tags-container">
        <template v-if="isMobile">
            <portal to="modals">
                <tags-modal
                    v-if="showTagsModal"
                    v-model="showTagsModal"
                    :tags="filteredTags"
                    :selected-tags="selectedTags"
                    @update:selectedTags="updateSelectedTags"
                ></tags-modal>
            </portal>
            <div class="tag-container" @click="showTagsModal = true">
                <div class="tag-container">
                    <span
                        v-for="(tagName, index) in selectedTags"
                        :key="'selected-' + index"
                        class="tag"
                        :style="{ width: selectedTags.length === 1 ? '20px' : '8px' }"
                    >
                        <span class="tag-color-indicator">
                            <feather-tag
                                style="width: 15px"
                                class="icon icon-tag"
                                :stroke="getTagColor(tagName)"
                                :fill="getTagColor(tagName)"
                                :color="getTagColor(tagName)"
                            ></feather-tag>
                        </span>
                    </span>
                    <span
                        :class="{
                            'tag-icon': true,
                            'tag-icon-active': selectedTags.length,
                            'tag-icon-active-element': selectedTags.length > 0,
                        }"
                    >
                        <i class="icon icon-tag"></i>
                    </span>
                </div>
            </div>
        </template>
        <template v-else>
            <at-dropdown :placement="position" :trigger="trigger" @on-dropdown-command="onDropdownCommand">
                <at-tooltip :content="tooltipContent">
                    <div class="tag-container">
                        <span
                            v-for="(tagName, index) in selectedTags"
                            :key="'selected-' + index"
                            class="tag"
                            :style="{ width: selectedTags.length === 1 ? '13px' : 'px' }"
                        >
                            <feather-tag
                                style="width: 20px"
                                class="icon icon-tag"
                                :stroke="getTagColor(tagName)"
                                :fill="getTagColor(tagName)"
                                :color="getTagColor(tagName)"
                            ></feather-tag>
                        </span>
                        <span
                            :class="{
                                'tag-icon': true,
                                'tag-icon-active': selectedTags.length,
                                'tag-icon-active-element': selectedTags.length > 0,
                            }"
                        >
                            <i class="icon icon-tag"></i>
                        </span>
                    </div>
                </at-tooltip>
                <at-dropdown-menu slot="menu" @click.native.stop>
                    <div class="dropdown-search">
                        <at-input v-model="search" :placeholder="$t('dashboard.tagSearch')"></at-input>
                    </div>
                    <at-checkbox-group v-model="selectedTags" @input="emitSelectedTags">
                        <at-checkbox
                            v-for="(tagObj, index) in filteredTags"
                            :key="index"
                            :label="tagObj.attributes.name"
                        >
                            <span
                                class="tag-color-indicator"
                                :style="{ backgroundColor: tagObj.attributes.color }"
                            ></span>
                            {{ tagObj.attributes.name }}
                        </at-checkbox>
                    </at-checkbox-group>
                    <at-button
                        v-if="checkCreateAccess"
                        class="create-tag-button"
                        :disabled="isCreateButtonDisabled"
                        @click="createTag"
                    >
                        <i class="icon icon-plus"></i>
                        {{ $t('dashboard.buttons.createTag')
                        }}<template v-if="createTagButtonText">"{{ createTagButtonText }}"</template>
                    </at-button>
                </at-dropdown-menu>
            </at-dropdown>
        </template>
    </div>
</template>

<script>
    import TagsModal from './TagsModal.vue';
    import { TagIcon } from 'vue-feather-icons';
    import { mapGetters } from 'vuex';

    export default {
        name: 'TagsContainer',
        components: {
            TagsModal,
            'feather-tag': TagIcon,
        },
        props: {
            tags: {
                type: Array,
                required: true,
            },
            selectedTagIDs: {
                type: Array,
                default: null,
            },
        },
        data() {
            return {
                position: 'bottom',
                trigger: 'click',
                selectedTags: [],
                search: '',
                isDataLoading: false,
                showTagsModal: false,
            };
        },
        computed: {
            ...mapGetters('user', ['user']),
            ...mapGetters('tag', ['service']),
            isMobile() {
                return window.innerWidth <= 992;
            },
            filteredTags() {
                return this.tags.filter(tag => tag.attributes.name.toLowerCase().includes(this.search.toLowerCase()));
            },
            isCreateButtonDisabled() {
                return this.search.trim() === '';
            },
            createTagButtonText() {
                return this.search.trim();
            },
            tooltipContent() {
                if (this.selectedTags.length > 0) {
                    return this.selectedTags.join(', ');
                }
                return 'Select tags';
            },
        },
        created() {
            this.handleSelectedTags();
        },
        methods: {
            generateRandomColor() {
                return '#' + Math.floor(Math.random() * 16777215).toString(16);
            },
            updateSelectedTags(newTags) {
                this.selectedTags = newTags;
                this.emitSelectedTags(this.selectedTags);
            },
            onDropdownCommand(command) {},
            getTagColor(tagName) {
                const tag = this.tags.find(tagObj => tagObj.attributes.name === tagName);
                return tag ? tag.attributes.color : '#888888';
            },
            async createTag() {
                const newTagName = this.search.trim();
                const newTagExists = this.tags.some(
                    tag => tag.attributes.name.toLowerCase() === newTagName.toLowerCase(),
                );

                if (newTagName && !newTagExists) {
                    const newTagColor = this.generateRandomColor();
                    const newTagObj = {
                        name: newTagName,
                        color: newTagColor,
                    };
                    const { data } = await this.service.save(newTagObj);

                    if (!data) {
                        return;
                    }

                    this.tags.push(data.data);
                    this.search = '';
                    this.emitSelectedTags([newTagName]);
                }
            },
            handleSelectedTags() {
                if (this.selectedTagIDs && this.selectedTagIDs.length > 0) {
                    this.selectedTags = this.selectedTagIDs.map(tag => {
                        return tag.attributes.name;
                    });
                }
            },
            emitSelectedTags(names) {
                const tag = names.map(name => {
                    const obj = this.tags.find(ob => ob.attributes.name === name);
                    return obj;
                });
                this.$emit('tag-selected', tag);
            },
            checkCreateAccess() {
                const roles = this.user.roles_access;
                return [roles.isAdmin, roles.isManager].some(value => value);
            },
        },
        watch: {
            selectedTagIDs(values) {
                if (values.every(value => typeof value === 'object')) {
                    this.selectedTags = values.map(tag => {
                        return tag.attributes.name;
                    });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .tag-color-indicator {
        display: inline-block;
        width: 12px;
        height: 12px;
        vertical-align: -1px;
        border-radius: 50%;
        margin-left: 5px;
        margin-right: 5px;
    }
</style>
