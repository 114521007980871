<template>
    <div class="timeline-header">
        <at-input
            v-model="autocompleteInput"
            class="timeline-input"
            :placeholder="question"
            @focus="onInputFocus"
            @input="filterAutocompleteSuggestions"
        ></at-input>
        <transition name="slide-up">
            <div v-show="dropdownVisible" class="autocomplete-dropdown" @click.stop>
                <h6>Previously tracked time entries</h6>
                <div>
                    <div class="autocomplete-dropdown-container">
                        <ul>
                            <li v-for="subItem in autocompleteSuggestions" :key="subItem.id">
                                <at-tooltip placement="right" :content="formatTooltipContent(subItem)">
                                    <div @click="onSelectItem(subItem)">
                                        {{ subItem.description }} - {{ subItem.project }} - {{ subItem.task }} -
                                        {{ subItem.time }}
                                    </div>
                                </at-tooltip>
                            </li>
                        </ul>
                    </div>
                </div>
            </div>
        </transition>
        <AdditionalElements
            :selectedTaskID="selectedTaskID"
            :selectedTagIDs="selectedTagIDs"
            :selectedBillable="selectedBillable"
            @task-selected="handleSelectedTask"
            @tag-selected="handleSelectedTags"
            @billable-selected="handleBillable"
        />
        <div class="timeline-interface">
            <Timer :time="time" :isRunning="isTimerRunning" />
            <at-button
                class="timer-button"
                :class="{ active: isTimerRunning, inactive: !isTimerRunning }"
                @click="toggleTimer"
            >
                <span class="timer-button-stop"
                    ><i class="icon icon-stop-circle"></i>{{ $t('dashboard.buttons.stop') }}</span
                >
                <span class="timer-button-start"
                    ><i class="icon icon-play"></i>{{ $t('dashboard.buttons.start') }}</span
                >
            </at-button>
        </div>
    </div>
</template>
<script>
    import Timer from './Timer.vue';
    import TimerService from '../services/timer.service';
    import AdditionalElements from './AdditionalElements.vue';
    import debounce from 'lodash/debounce';
    import TimeIntervalsService from '@/services/resource/time-interval.service';
    import WebSocket from 'ws';

    export default {
        name: 'ConnectionTimerInterface',
        props: {
            itemData: {
                type: Object,
                default: () => ({}),
            },
            timelineItems: {
                type: Array,
                default: () => [],
            },
            timeIntervalStart: {
                type: Object,
                default: undefined,
            },
            copyDescription: {
                type: String,
                default: '',
            },
        },
        components: {
            AdditionalElements,
            Timer,
        },
        data() {
            return {
                question: this.$t('dashboard.question'),
                selectedTaskID: null,
                selectedTagIDs: null,
                selectedTimeInterval: null,
                selectedBillable: false,
                timerService: new TimerService(),
                autocompleteInput: '',
                autocompleteSuggestions: [],
                dropdownVisible: false,
                isTimerRunning: false,
                time: 0,
                elapsedSeconds: 0,
                startTime: null,
                timeIntervalsService: new TimeIntervalsService(),
                redisIntervalID: null,
                ws: null,
            };
        },
        mounted() {
            document.addEventListener('click', this.handleOutsideClick);
        },
        created() {
            this.initializeWebSocket();
        },
        computed: {
            tagIDs() {
                if (this.selectedTagIDs) {
                    return this.selectedTagIDs.map(tag => {
                        return tag.attributes.id;
                    });
                }
                return [];
            },
        },
        methods: {
            handleSelectedTask(task) {
                this.selectedTaskID = task.attributes.id;
                if (this.startTime) {
                    this.updateTimeInterval({ task_id: this.selectedTaskID }, this.redisIntervalID);
                }
            },
            handleSelectedTags(tags) {
                this.selectedTagIDs = tags;
                if (this.startTime) {
                    this.updateTimeInterval({ tag_ids: this.tagIDs }, this.redisIntervalID);
                }
            },
            handleBillable(value) {
                this.selectedBillable = value;
                if (this.startTime) {
                    this.updateTimeInterval({ billable: this.selectedBillable }, this.redisIntervalID);
                }
            },
            formatTooltipContent(subItem) {
                return `${subItem.text} - ${subItem.project} - ${subItem.task} - ${subItem.time}`;
            },
            onInputFocus() {
                if (this.timelineItems.length > 0) {
                    const items = this.timelineItems[0].items.slice(0, 6);
                    this.autocompleteSuggestions = items;
                    this.dropdownVisible = true;
                }
            },
            onSelectItem(subItem) {
                this.autocompleteInput = subItem.description;
                this.selectedTaskID = subItem.task_id;
                this.selectedTagIDs = subItem.tags;
                this.selectedBillable = subItem.billable;
                this.dropdownVisible = false;
                this.startTimer();
            },
            handleOutsideClick(event) {
                if (!this.$el.contains(event.target)) {
                    this.dropdownVisible = false;
                }
            },
            filterAutocompleteSuggestions: debounce(async function (newDescription) {
                if (this.selectedTaskID && this.startTime) {
                    this.updateTimeInterval({ description: newDescription }, this.redisIntervalID);
                }
            }, 350),
            updateTimeInterval(params, id) {
                this.timeIntervalsService.update(params, id);
            },
            // Имитация установления соединения с WebSocket сервером
            initializeWebSocket() {
                // console.log('WebSocket connection simulated');
                this.ws = {
                    send: message => {
                        const data = JSON.parse(message);
                        // Имитация обработки сообщений сервером
                        switch (data.action) {
                            case 'start-timer':
                                this.requestToStartTimer();
                                this.runFakeTimer();
                                break;
                            case 'stop-timer':
                                this.timerService.stop();
                                this.redisIntervalID = null;
                                this.stopFakeTimer();
                                // window.location.reload();
                                break;
                            default:
                                console.log('Unknown action:', data.action);
                        }
                    },
                    close: () => {
                        this.stopFakeTimer();
                        // console.log('WebSocket connection closed');
                    },
                };
            },
            runFakeTimer() {
                // Запускаем интервал, если он еще не запущен
                this.startTime = Date.now();
                if (!this.interval) {
                    this.interval = setInterval(() => {
                        const now = Date.now();
                        this.time = Math.floor((now - this.startTime) / 1000);
                    }, 1000);
                }
            },
            stopFakeTimer() {
                if (this.interval) {
                    clearInterval(this.interval);
                    this.interval = null;
                    this.startTime = null;
                    this.time = 0;
                    this.elapsedSeconds = 0;
                }
            },
            /*initializeWebSocket() {
            this.ws = new WebSocket('wss://yourserver.com/path');

            this.ws.onmessage = event => {
                const data = JSON.parse(event.data);
                if (data.type === 'timer-update') {
                    this.time = new Date(data.time);
                }
            };

            this.ws.onopen = () => {
                console.log('WebSocket connection established');
            };

            this.ws.onerror = error => {
                console.error('WebSocket error:', error);
            };

            this.ws.onclose = () => {
                console.log('WebSocket connection closed');
            };
        },*/
            startTimer() {
                // Отправляем сообщение о запуске таймера на сервер
                if (this.selectedTaskID) {
                    this.ws.send(JSON.stringify({ action: 'start-timer' }));
                    if (!this.isTimerRunning) {
                        this.runFakeTimer();
                        this.isTimerRunning = true;
                    }
                } else {
                    this.$Notify({
                        type: 'warning',
                        title: 'Warning',
                        message: 'Choose Task',
                    });
                }
            },
            async requestToStartTimer() {
                const { data } = await this.timerService.start({
                    task_id: this.selectedTaskID,
                    description: this.autocompleteInput,
                    tag_ids: this.tagIDs,
                    billable: this.selectedBillable,
                });
                this.redisIntervalID = data.id;
            },
            stopTimer() {
                // Отправляем сообщение об остановке таймера на сервер
                this.ws.send(JSON.stringify({ action: 'stop-timer' }));
                if (this.isTimerRunning) {
                    this.stopFakeTimer();
                    this.isTimerRunning = false;
                }
                this.$emit('load-data');
            },
            toggleTimer() {
                // Переключение состояния таймера
                if (this.isTimerRunning) {
                    this.stopTimer();
                } else {
                    this.startTimer();
                }
            },
        },
        beforeDestroy() {
            document.removeEventListener('click', this.handleOutsideClick);
            if (this.ws) {
                this.ws.close();
            }
        },
        watch: {
            autocompleteInput(newValue) {
                if (newValue.length >= 1) {
                    this.filterAutocompleteSuggestions(newValue);
                } else {
                    this.autocompleteSuggestions = [];
                    this.dropdownVisible = false;
                }
            },
            timeIntervalStart(params) {
                this.autocompleteInput = params.description;
                this.selectedTaskID = params.task_id;
                this.selectedTagIDs = params.tags;
                this.selectedBillable = params.billable;
                this.startTimer();
            },
            copyDescription(description) {
                this.autocompleteInput = description;
            },
        },
    };
</script>
<style lang="scss" scoped>
    .autocomplete-dropdown {
        position: absolute;
        width: 100%;
        height: auto;
        max-height: 360px;
        max-width: 430px;
        overflow: visible;
        z-index: 100;
        background-color: $background-color-light;
        border: 1px solid rgb(235, 231, 235);
        box-shadow: 0px 4px 16px rgba(0, 0, 0, 0.15);
        border-radius: 4px;
        padding: 15px;
        padding-bottom: 10px;
        top: calc(100% - 5px);
        ul {
            max-width: 400px;
            list-style: none;
            overflow: visible;
            margin: 0;
            padding: 0;
        }
        li {
            padding: 15px;
            padding-top: 5px;
            padding-bottom: 5px;
            cursor: pointer;
            &:hover {
                background-color: #f0f0f0;
            }
        }
    }
    @media (max-width: 600px) {
        .autocomplete-dropdown {
            max-width: 360px;
        }
        .timeline .autocomplete-dropdown-container .at-tooltip__trigger > div {
            max-width: 320px;
        }
    }
</style>
