<template>
    <div class="timeline">
        <div v-if="activeSection === 'interval'" class="time-interval">
            <div class="at-container sidebar col-md-12">
                <TimelineSidebar
                    :active-task="activeTask"
                    :isDataLoading="isDataLoading"
                    :startDate="start"
                    :endDate="end"
                />
            </div>
            <div class="controls-row flex-between">
                <div class="flex">
                    <Calendar
                        class="controls-row__item"
                        :sessionStorageKey="sessionStorageKey"
                        @change="onCalendarChange"
                    />
                </div>

                <div class="flex">
                    <router-link
                        v-if="$can('viewManualTime', 'dashboard')"
                        to="/time-intervals/new"
                        class="controls-row__item"
                    >
                        <at-button class="controls-row__btn" icon="icon-edit">
                            {{ $t('control.add_time') }}
                        </at-button>
                    </router-link>
                </div>
            </div>

            <div class="at-container">
                <TimelineDayGraph
                    v-if="type === 'day'"
                    class="graph"
                    :start="start"
                    :end="end"
                    :events="userEvents"
                    :timezone="timezone"
                    @selectedIntervals="onIntervalsSelect"
                    @remove="onBulkRemove"
                />
                <TimelineCalendarGraph v-else class="graph" :start="start" :end="end" :timePerDay="userTimePerDay" />

                <TimelineScreenshots
                    v-if="type === 'day' && intervals && Object.keys(intervals).length"
                    ref="timelineScreenshots"
                    @on-remove="recalculateStatistic"
                    @onSelectedIntervals="setSelectedIntervals"
                />
                <preloader v-if="isDataLoading" class="timeline__loader" :is-transparent="true" />

                <TimeIntervalEdit
                    v-if="user.roles_access.is_admin"
                    :intervals="selectedIntervals"
                    @remove="onBulkRemove"
                    @edit="loadData"
                    @close="clearIntervals"
                />
            </div>
        </div>
        <portal-target name="modals"></portal-target>
    </div>
</template>

<script>
    import moment from 'moment';
    import debounce from 'lodash/debounce';
    import { mapGetters, mapMutations } from 'vuex';
    import Calendar from '@/components/Calendar';
    import TimelineSidebar from '../../components/TimelineSidebar';
    import TimelineDayGraph from '../../components/TimelineDayGraph';
    import TimelineCalendarGraph from '../../components/TimelineCalendarGraph';
    import TimelineScreenshots from '../../components/TimelineScreenshots';
    import { getDateToday } from '@/utils/time';
    import { getStartOfDayInTimezone, getEndOfDayInTimezone } from '@/utils/time';
    import cloneDeep from 'lodash/cloneDeep';
    import TimeIntervalEdit from '../../components/TimeIntervalEdit';
    import Preloader from '@/components/Preloader';

    const updateInterval = 60 * 1000;

    export default {
        name: 'Timeline',
        components: {
            Calendar,
            TimelineSidebar,
            TimelineDayGraph,
            TimelineCalendarGraph,
            TimelineScreenshots,
            TimeIntervalEdit,
            Preloader,
        },
        data() {
            const today = this.getDateToday();
            const sessionStorageKey = 'ust.session.storage.timeline';

            return {
                timelineItems: [],
                type: 'day',
                start: today,
                end: today,
                datepickerDateStart: '',
                datepickerDateEnd: '',
                activeTask: +localStorage.getItem('timeline.active-task') || 0,
                showExportModal: false,
                selectedIntervals: [],
                sessionStorageKey: sessionStorageKey,
                isDataLoading: false,
                activeSection: 'interval',
                selectedItem: null,
            };
        },
        created() {
            localStorage['dashboard.tab'] = 'timeline';
            this.loadData();
            this.updateHandle = setInterval(() => this.loadData(false), updateInterval);
            this.$emit('set-active', 'intervals');
        },
        beforeDestroy() {
            clearInterval(this.updateHandle);
            this.service.unloadIntervals();
        },
        methods: {
            getDateToday,
            getStartOfDayInTimezone,
            getEndOfDayInTimezone,
            ...mapMutations({
                setTimezone: 'dashboard/setTimezone',
            }),
            updateTimelineItems(itemToUpdate) {
                const index = this.timelineItems.findIndex(item => item.date === itemToUpdate.date);
                if (index !== -1) {
                    this.timelineItems[index].items = itemToUpdate.items;
                }
            },
            removeTimelineEntry(itemToRemove) {
                this.timelineItems = this.timelineItems.filter(item => item.date !== itemToRemove.date);
            },
            selectItem(item) {
                this.selectedItem = item;
            },
            setActive(section) {
                this.activeSection = section;
            },
            loadData: debounce(async function (withLoadingIndicator = true) {
                this.isDataLoading = withLoadingIndicator;

                if (!this.user || !this.user.id) {
                    this.isDataLoading = false;
                    return;
                }
                const userIDs = [this.user.id];

                const startAt = new Date(this.start);
                const endAt = new Date(this.end);

                await this.service.load(userIDs, null, startAt, endAt, this.timezone);

                this.isDataLoading = false;
            }, 350),
            onCalendarChange({ type, start, end }) {
                this.type = type;
                this.start = start;
                this.end = end;

                this.service.unloadIntervals();

                this.loadData();
            },
            onIntervalsSelect(event) {
                this.selectedIntervals = event ? [event] : [];
            },
            onBulkRemove(intervals) {
                let totalIntervals = cloneDeep(this.intervals);
                intervals.forEach(interval => {
                    const userIntervals = cloneDeep(totalIntervals).filter(userInterval => {
                        return interval.attributes.id !== userInterval.attributes.id;
                    });

                    const deletedDuration = moment(interval.attributes.end_at).diff(
                        interval.attributes.start_at,
                        'seconds',
                    );

                    totalIntervals = userIntervals;
                });
                this.$store.commit('dashboard/setIntervals', totalIntervals);

                this.clearIntervals();
            },
            onTimezoneChange(timezone) {
                this.setTimezone(timezone);
            },
            recalculateStatistic(intervals) {
                this.onBulkRemove(intervals);
            },
            setSelectedIntervals(intervalIds) {
                this.selectedIntervals = intervalIds;
            },
            clearIntervals() {
                if (this.$refs.timelineScreenshots) {
                    this.$refs.timelineScreenshots.clearSelectedIntervals();
                }
                this.selectedIntervals = [];
            },
            checkAccess() {
                return this.user.roles_access.is_admin || this.user.roles_access.is_reporter;
            },
        },
        computed: {
            ...mapGetters('dashboard', ['intervals', 'service', 'timePerDay', 'timePerProject', 'timezone']),
            ...mapGetters('user', ['user']),
            userEvents() {
                if (!this.user || !this.user.id || !this.intervals[0]) {
                    return [];
                }

                return this.intervals.filter(interval => {
                    return interval.attributes.user_id === this.user.id;
                });
            },
            userTimePerDay() {
                if (!this.user || !this.user.id || !this.timePerDay[this.user.id]) {
                    return {};
                }

                return this.timePerDay[this.user.id];
            },
        },
        watch: {
            user() {
                this.loadData();
            },
            timezone() {
                this.service.unloadIntervals();
                this.loadData();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .at-container::v-deep {
        .modal-screenshot {
            a {
                max-height: inherit;

                img {
                    max-height: inherit;
                    object-fit: fill;
                }
            }
        }
    }
    .at-container {
        position: relative;
        padding: 1em;

        &:not(:last-child) {
            padding-right: 1.5em;
        }
    }

    .sidebar {
        padding: 30px 0;
    }

    .timeline {
        &__loader {
            z-index: 0;
            border-radius: 20px;
        }
    }

    .timeline-type {
        margin-left: 10px;
        border-radius: 5px;

        .at-btn:first-child {
            border-radius: 5px 0 0 5px;
        }

        .at-btn:last-child {
            border-radius: 0 5px 5px 0;
        }

        &-btn {
            border: 1px solid #eeeef5;
            color: #b1b1be;
            font-size: 15px;
            font-weight: 500;
            height: 40px;

            &.active {
                color: #ffffff;
                background: #2e2ef9;
            }
        }
    }

    .controls-row {
        z-index: 1;
        position: relative;

        &__btn {
            .theme-dark & {
                &:hover {
                    color: #c4c4cf;
                    background-color: #282828;
                }
            }
        }
    }

    .graph {
        width: 100%;
    }

    .pr-1 {
        padding-right: 1em;
    }
</style>
