<template>
    <div class="timeline">
        <div class="timeline-times">
            <div v-if="tasks && tags" class="timeline-menu">
                <ConnectionTimerInterface
                    :itemData="selectedItem"
                    :timelineItems="timelineItems"
                    :timeIntervalStart="timeIntervalParams"
                    :copyDescription="onCopyDescription"
                    @load-data="runLoadData"
                />
            </div>
            <div v-if="tasks && tags" class="timeline-items-list">
                <h2>{{ $t('dashboard.list') }}</h2>
                <TimelineItem
                    v-for="(item, index) in timelineItems"
                    :key="index"
                    :item="item"
                    @update:item="updateTimelineItems"
                    @start-task-timer="startTaskTimer"
                    @copy-description="handleDescription"
                    @load-data="runLoadData"
                />
            </div>
        </div>
        <portal-target name="modals"></portal-target>
    </div>
</template>

<script>
    import debounce from 'lodash/debounce';
    import { mapGetters, mapMutations } from 'vuex';
    import { getDateToday } from '@/utils/time';
    import TimelineItem from '../../components/TimelineItem.vue';
    import ConnectionTimerInterface from '../../components/ConnectionTimerInterface.vue';

    export default {
        name: 'ShowTimes',
        components: {
            ConnectionTimerInterface,
            TimelineItem,
        },
        data() {
            return {
                type: 'day',
                activeTask: +localStorage.getItem('timeline.active-task') || 0,
                isDataLoading: false,
                timelines: null,
                timelineItems: [],
                selectedItem: null,
                timeIntervalParams: null,
                onCopyDescription: null,
                page: 1,
                tasks: null,
                tags: null,
            };
        },
        async created() {
            await this.loadData();
            await this.loadTasksData();
            await this.loadTagsData();
            window.addEventListener('scroll', this.handleScroll);
        },
        beforeDestroy() {
            window.removeEventListener('scroll', this.handleScroll);
        },
        methods: {
            getDateToday,
            ...mapMutations({
                setTimezone: 'dashboard/setTimezone',
            }),
            runLoadData: debounce(function () {
                setTimeout(async () => {
                    try {
                        this.page = 1;
                        const { data } = await this.service.loadTimes({ page: this.page });
                        if (!data) {
                            return;
                        }

                        const newItems = this.transformData(data.data);
                        this.timelineItems = newItems.sort((a, b) => new Date(b.date) - new Date(a.date));
                    } catch (error) {
                        console.error('Error loading data:', error);
                    }
                }, 500);
            }, 0),
            async handleScroll() {
                const bottomOfWindow = window.scrollY + window.innerHeight >= document.documentElement.scrollHeight;
                if (bottomOfWindow) {
                    this.page += 1;
                    this.additionalDataLoad();
                }
            },
            handleDescription(description) {
                this.onCopyDescription = description;
            },
            setActive(section) {
                this.activeSection = section;
            },
            selectItem(item) {
                this.selectedItem = item;
            },
            loadData: debounce(async function (withLoadingIndicator = true) {
                this.isDataLoading = withLoadingIndicator;

                const { data } = await this.service.loadTimes({ page: this.page });

                if (!data) {
                    return;
                }
                this.timelines = data.data;
                this.timelineItems = this.transformedData;

                this.isDataLoading = false;
            }, 350),

            additionalDataLoad: debounce(async function (withLoadingIndicator = true) {
                this.isDataLoading = withLoadingIndicator;

                const { data } = await this.service.loadTimes({ page: this.page });

                if (!data) {
                    return;
                }

                const newItems = this.transformData(data.data);

                newItems.forEach(newItem => {
                    const existingItem = this.timelineItems.find(item => item.date === newItem.date);
                    if (existingItem) {
                        existingItem.items.push(...newItem.items);
                    } else {
                        this.timelineItems.push(newItem);
                    }
                });

                // Sort the timeline items by created_at field
                this.timelineItems.forEach(item => {
                    item.items.sort((a, b) => new Date(b.start_at) - new Date(a.start_at));
                });

                this.isDataLoading = false;
            }, 350),

            startTaskTimer(timeIntervalParams) {
                this.timeIntervalParams = timeIntervalParams;
            },
            updateTimelineItems() {
                const newTimelineItems = this.timelineItems.filter(item => item.items.length != 0);
                this.timelineItems = newTimelineItems;
            },

            formatDuration(seconds) {
                const hrs = Math.floor(seconds / 3600);
                const mins = Math.floor((seconds % 3600) / 60);
                const secs = seconds % 60;
                return `${hrs}:${mins.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
            },
            transformData(timelines) {
                const groupedByDate = timelines.reduce((acc, curr) => {
                    const date = new Date(curr.attributes.start_at).toDateString();
                    if (!acc[date]) acc[date] = [];
                    acc[date].push(curr);
                    return acc;
                }, {});

                return Object.keys(groupedByDate)
                    .sort((a, b) => new Date(b) - new Date(a)) // Sort dates in descending order
                    .map(date => ({
                        date,
                        items: groupedByDate[date].map(item => ({
                            description: item.attributes.description || 'No description',
                            project: item.attributes.task.attributes.project.attributes.name,
                            task: item.attributes.task.attributes.task_name,
                            duration: this.formatDuration(item.attributes.duration),
                            task_id: item.attributes.task.attributes.id,
                            id: item.attributes.id,
                            created_at: new Date(item.attributes.created_at),
                            tags: item.attributes.tags,
                            billable: item.attributes.billable,
                            start_at: item.attributes.start_at,
                            end_at: item.attributes.end_at,
                        })),
                    }));
            },

            loadTasksData: debounce(async function (withLoadingIndicator = true) {
                this.isDataLoading = withLoadingIndicator;
                const tasks = await this.$store.dispatch('task/getTasks');

                if (!tasks) {
                    return;
                }
                this.tasks = tasks;
                this.isDataLoading = false;
            }, 350),

            loadTagsData: debounce(async function (withLoadingIndicator = true) {
                this.isDataLoading = withLoadingIndicator;
                const tags = this.$store.dispatch('tag/getTags');

                if (!tags) {
                    return;
                }

                this.tags = tags;
                this.isDataLoading = false;
            }, 350),
        },
        computed: {
            ...mapGetters('dashboard', ['service']),
            transformedData() {
                return this.transformData(this.timelines);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .at-container::v-deep {
        .modal-screenshot {
            a {
                max-height: inherit;

                img {
                    max-height: inherit;
                    object-fit: fill;
                }
            }
        }
    }
    .at-container {
        position: relative;
        padding: 1em;

        &:not(:last-child) {
            padding-right: 1.5em;
        }
    }

    .sidebar {
        padding: 30px 0;
    }

    .timeline {
        &__loader {
            z-index: 0;
            border-radius: 20px;
        }
    }

    .timeline-type {
        margin-left: 10px;
        border-radius: 5px;

        .at-btn:first-child {
            border-radius: 5px 0 0 5px;
        }

        .at-btn:last-child {
            border-radius: 0 5px 5px 0;
        }

        &-btn {
            border: 1px solid #eeeef5;
            color: #b1b1be;
            font-size: 15px;
            font-weight: 500;
            height: 40px;

            &.active {
                color: #ffffff;
                background: #2e2ef9;
            }
        }
    }

    .controls-row {
        z-index: 1;
        position: relative;

        &__btn {
            .theme-dark & {
                &:hover {
                    color: #c4c4cf;
                    background-color: #282828;
                }
            }
        }
    }

    .graph {
        width: 100%;
    }

    .pr-1 {
        padding-right: 1em;
    }
</style>
