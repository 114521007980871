<template>
    <at-modal v-model="localShowModal" @on-cancel="closeModal">
        <template v-slot:header>
            <h3>{{ $t('dashboard.taskSpan') }}</h3>
        </template>
        <div class="modal-content">
            <div class="dropdown-search">
                <at-input v-model="search" placeholder="Search project, task..."></at-input>
            </div>
            <div v-if="filteredTasks.length === 0" class="not-found-message">{{ $t('dashboard.noTasks') }}</div>
            <div v-else class="projects-list">
                <div
                    v-for="(task, index) in filteredTasks"
                    :key="index"
                    class="project-entry"
                    @click="selectAndClose(task)"
                >
                    <span
                        class="color-indicator"
                        :style="{
                            backgroundColor: task.attributes.color,
                            width: '10px',
                            display: 'inline-block',
                            height: '10px',
                            borderRadius: '50%',
                        }"
                    ></span>
                    <span class="project-name" :style="{ color: task.attributes.color }">{{
                        task.attributes.project.name
                    }}</span
                    >,
                    <span class="task-name">{{ task.attributes.task_name }}</span>
                    <div class="project-desc">{{ task.attributes.description }}</div>
                </div>
            </div>
        </div>
        <template v-slot:footer>
            <at-button class="create-project-button" @click="createTask">
                <i class="icon icon-plus"></i>
                {{ $t('dashboard.buttons.createTask') }}
            </at-button>
        </template>
    </at-modal>
</template>

<script>
    export default {
        name: 'TaskModal',
        props: {
            value: {
                type: Boolean,
                default: false,
            },
            projects: {
                type: Array,
                required: true,
            },
        },
        data() {
            return {
                search: '',
            };
        },
        computed: {
            filteredTasks() {
                return this.projects.filter(task => {
                    const taskName = task.attributes && task.attributes.task_name;
                    return taskName && taskName.toLowerCase().includes(this.search.toLowerCase());
                });
            },
            localShowModal: {
                get() {
                    return this.value;
                },
                set(value) {
                    this.$emit('input', value);
                },
            },
        },
        methods: {
            closeModal() {
                this.$emit('input', false);
            },
            selectAndClose(task) {
                this.$emit('select-task', task);
                this.closeModal();
            },
            createTask() {
                this.$router.push('/tasks/new');
                this.closeModal();
            },
        },
    };
</script>
<style lang="scss" scoped>
    .project-entry {
        cursor: pointer;
    }
    @media (max-width: 600px) {
        .at-modal {
            width: 100% !important;
        }
    }
</style>
