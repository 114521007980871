<template>
    <v-data-table :headers="headers" :items="tableData" :items-per-page="5" class="elevation-1">
        <template v-slot:item="{ item }">
            <tr v-for="(subItem, index) in item.items" :key="index">
                <td :style="{ borderTopLeftRadius: '8px', borderBottomLeftRadius: '8px' }">
                    <at-input
                        v-model="subItem.description"
                        class="input-data"
                        @input="updateText(subItem.description, subItem)"
                    />
                </td>
                <td v-if="tasks" class="projectContainer">
                    <div class="project-group">
                        <TaskContainer
                            tooltip-content="Select task"
                            :selectedTaskID="subItem.task_id"
                            :tasks="tasks"
                            @task-selected="handleSelectTask($event, subItem)"
                        />
                        <span v-if="!subItem.task_id" class="project">
                            <span>
                                <i class="icon">
                                    <img src="@/icon/tasks-solid.svg" alt="tasks" />
                                </i>
                            </span>
                        </span>
                    </div>
                </td>
                <td v-if="tags" class="tagsContainer">
                    <TagsContainer
                        :tags="tags"
                        :selectedTagIDs="subItem.tags"
                        @tag-selected="handleSelectedTags($event, subItem)"
                    />
                </td>
                <td class="timeContainer">
                    <time-display
                        :ref="`timeDisplayRef_${index}`"
                        :startAt="subItem.start_at"
                        :endAt="subItem.end_at"
                        @update:date="handleStartEndDate($event, subItem)"
                    />
                </td>
                <td class="durationContainer" @click.stop="toggleTimeDisplayDropdown(index)">{{ subItem.duration }}</td>
                <td class="additionalPlansContainer">
                    <AdditionalPlans
                        :selectedBillable="subItem.billable"
                        @billable-status="handleBillablePlan($event, subItem)"
                    />
                </td>
                <td class="playTimerContainer">
                    <at-tooltip content="Continue time entry">
                        <button><i class="icon icon-play" @click="startTimer(subItem)"></i></button>
                    </at-tooltip>
                </td>
                <td
                    class="menuVerticalContainer"
                    :style="{ borderTopRightRadius: '8px', borderBottomRightRadius: '8px' }"
                >
                    <at-dropdown
                        :placement="position"
                        :trigger="trigger"
                        @on-dropdown-command="onDropdownCommand($event, subItem)"
                    >
                        <at-tooltip content="More actions">
                            <button><i class="icon icon-more-vertical"></i></button>
                        </at-tooltip>
                        <at-dropdown-menu slot="menu" class="dropdown-menu">
                            <at-dropdown-item v-for="(item, index) in dropdownItems" :key="index" :name="item.name">
                                {{ item.text }}
                            </at-dropdown-item>
                        </at-dropdown-menu>
                    </at-dropdown>
                </td>
            </tr>
            <DeleteModal
                :visible="isDeleteModalVisible"
                :message="deleteMessage"
                @close="closeDeleteModal"
                @confirm="confirmDeleteItem"
            />
        </template>
    </v-data-table>
</template>

<script>
    import AdditionalPlans from './AdditionalPlans.vue';
    import TagsContainer from './TagsContainer.vue';
    import TaskContainer from './TaskContainer.vue';
    import TimeDisplay from './TimeDisplay.vue';
    import { mapGetters } from 'vuex';
    import TimeIntervalsService from '@/services/resource/time-interval.service';
    import DeleteModal from '@/components/DeleteModal';
    import debounce from 'lodash/debounce';

    export default {
        name: 'TimelineItem',
        components: { TaskContainer, TagsContainer, AdditionalPlans, TimeDisplay, DeleteModal },
        props: {
            item: {
                type: Object,
                required: true,
            },
        },
        data() {
            return {
                position: 'bottom',
                trigger: 'click',
                dropdownItems: [
                    { name: 'copy description', text: this.$t('dashboard.buttons.copyDescription') },
                    { name: 'delete', text: this.$t('dashboard.buttons.delete') },
                ],
                startTime: '09:30 AM',
                endTime: '12:59 PM',
                isDataLoading: false,
                timeIntervalsService: new TimeIntervalsService(),
                isDeleteModalVisible: false,
                deleteMessage: '',
                subItemToDelete: null,
            };
        },
        computed: {
            ...mapGetters('task', ['tasks']),
            ...mapGetters('tag', ['tags', 'service']),
            tableData() {
                return [this.item];
            },
            totalDuration() {
                const totalSeconds = this.item.items.reduce((total, subItem) => {
                    return total + this.hmsToSeconds(subItem.duration || '0:00:00');
                }, 0);
                return this.secondsToHMS(totalSeconds);
            },
            headers() {
                const isMobile = window.innerWidth < 600;
                return [
                    { text: this.item.date, value: 'date', sortable: !isMobile },
                    { text: '', value: 'text', sortable: !isMobile },
                    { text: '', value: 'project', sortable: !isMobile },
                    { text: '', value: '', sortable: !isMobile },
                    { text: '', value: 'time', sortable: !isMobile },
                    { text: '', value: '', sortable: !isMobile },
                    { text: '', value: '', sortable: !isMobile },
                    { text: this.totalDuration, value: 'duration', sortable: !isMobile },
                ];
            },
        },
        methods: {
            closeDeleteModal() {
                this.isDeleteModalVisible = false;
                this.subItemToDelete = null;
            },
            getTimeDisplayRef(subItem) {
                return () => `timeDisplayRef_${subItem.id}`;
            },
            startTimer(subItem) {
                this.$emit('start-task-timer', {
                    task_id: subItem.task_id,
                    description: subItem.description,
                    tags: subItem.tags,
                    billable: subItem.billable,
                });
            },
            toggleTimeDisplayDropdown(index) {
                const refName = `timeDisplayRef_${index}`;
                const timeDisplayComponent = this.$refs[refName];

                if (timeDisplayComponent && timeDisplayComponent[0]) {
                    timeDisplayComponent[0].toggleDropdown();
                } else {
                    console.error(`Компонент с ref ${refName} не найден.`);
                }
            },
            hmsToSeconds(hms) {
                const [hours, minutes, seconds] = hms.split(':').map(Number);
                return hours * 3600 + minutes * 60 + seconds;
            },
            handleSelectTask(task, subItem) {
                this.updateInterval({ task_id: task.attributes.id }, subItem.id);
            },
            async handleSelectedTags(tags, subItem) {
                const ids = tags.map(tag => tag.attributes.id);
                try {
                    await this.service.addTags({
                        object_id: subItem.id,
                        object_model_name: 'time_interval',
                        tag_ids: ids,
                    });
                } catch (error) {
                    console.error('Error updating tags:', error);
                }
            },
            handleBillablePlan(status, subItem) {
                this.updateInterval({ billable: status }, subItem.id);
            },
            handleStartEndDate(dateParams, subItem) {
                this.updateInterval(dateParams, subItem.id);
                this.$emit('load-data');
            },
            updateText: debounce(async function (description, subItem) {
                await this.updateInterval({ description: description }, subItem.id);
            }, 350),
            secondsToHMS(seconds) {
                const hours = Math.floor(seconds / 3600);
                const minutes = Math.floor((seconds % 3600) / 60);
                const secs = seconds % 60;
                return `${hours}:${minutes.toString().padStart(2, '0')}:${secs.toString().padStart(2, '0')}`;
            },
            copyDescription(subItem) {
                this.$emit('copy-description', subItem.description);
            },
            updateSelectedTags(subItem, selectedTag) {
                this.$set(subItem, 'selectedTags', selectedTag ? [selectedTag] : []);
            },
            async deleteItem(subItem) {
                this.isDeleteModalVisible = true;
                this.subItemToDelete = subItem;
                this.deleteMessage = `Are you sure you want to delete this item?`;
            },
            async confirmDeleteItem() {
                if (!this.subItemToDelete) return;

                const intervalID = this.subItemToDelete.id;
                await this.timeIntervalsService.bulkDelete({
                    intervals: [intervalID],
                });

                this.$Notify({
                    type: 'success',
                    title: this.$t('notification.record.delete.success.title'),
                    message: this.$t('notification.record.delete.success.message'),
                });

                const filteredItems = this.item.items.filter(item => item.id != intervalID);
                this.item.items = filteredItems;
                this.$emit('update:item');
                this.closeDeleteModal();
            },
            onDropdownCommand(command, subItem) {
                switch (command) {
                    case 'copy description':
                        this.copyDescription(subItem);
                        break;
                    case 'delete':
                        this.deleteItem(subItem);
                        break;
                    default:
                        console.warn(`Unknown command: ${command}`);
                }
            },
            async updateInterval(params, id) {
                await this.timeIntervalsService.update(params, id);
            },
        },
    };
</script>

<style lang="scss" scoped>
    .dropdown-menu {
        li {
            text-align: left;
            &:last-child {
                color: $color-primary;
            }
        }
    }
</style>
